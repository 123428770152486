<template>
  <div
    class="px-3 md:px-0 container max-w-8xl md:pt-10 mx-auto relative"
    v-if="experience"
  >
    <MobileHeader
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="block md:hidden"
    />
    <Heading
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="hidden md:block"
    />
    <div v-if="experience" class="mt-20 md:mt-5 bg-white rounded-xl">
      <div class="welcome-video bg-white rounded-xl">
        <div v-if="experience.exp_details != null">
          <Profile :values="experience.exp_details.details" />
        </div>
      </div>
    </div>
    <div class="my-5">
      <ExpFeedback :exp="obkSlugs.nha" />
    </div>
    <Footer />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Profile from "@/components/Demo/NHA.vue";
import ExpFeedback from "@/components/Demo/ExpFeedback.vue";
import MobileHeader from "@/components/Demo/MobileHeader.vue";
import Heading from "@/components/Demo/Heading.vue";
import obkSlugs from "@/common/slugs";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";

export default {
  components: {
    Profile,
    ExpFeedback,
    MobileHeader,
    Heading,
    Footer,
  },
  created() {
    ApiService.get(apiResource.demo.getDetails, {
      slug: obkSlugs.nha,
    })
      .then(({ data }) => {
        this.experience = data.data;
      })
      .catch(() => {});
  },
  data() {
    return {
      experience: null,
      obkSlugs,
    };
  },
  methods: {
    getHeaderText() {
      return this.experience.name;
    },
  },
};
</script>
<style scoped></style>
